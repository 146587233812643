import { Grid, SelectChangeEvent, Theme, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { RowType, VariableAllocationRow, VariableAllocationTable } from 'types/comp-allocations'
import { ExpandedTarget } from 'types/components'
import { Period, createLabelToIdMap, getOrderedLabels } from 'types/periods' // Adjust the import path as necessary
import AllocationTableDisplay from './AllocationTableDisplay/index' // Adjust the import path as necessary

type TemplateComponentsProps = {
  period: Period | null
  allocationTables: VariableAllocationTable[]
  targets: ExpandedTarget[]
  organizationLabels: Record<string, string>
  periodLabels: Record<string, string>
  exampleBonusAmount: number
  setAllocationTables: (tables: VariableAllocationTable[]) => void
  exampleSalary: number
  variableCompensation: number
  templateName: string
  setIsValid: (isValid: boolean) => void // New prop for setting validity
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
}

type ValidationResult = {
  isValid: boolean
  message: string
}

const TemplateComponents: FC<TemplateComponentsProps> = ({
  period,
  allocationTables,
  targets,
  organizationLabels,
  periodLabels,
  exampleBonusAmount,
  setAllocationTables,
  exampleSalary,
  variableCompensation,
  templateName,
  setIsValid, // New prop for setting validity
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
}) => {
  const [editingTableIndex, setEditingTableIndex] = useState<number | null>(null)
  const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null) // New state for tracking the editing row index
  const [editingLabel, setEditingLabel] = useState<string>('')
  const [editingTableName, setEditingTableName] = useState<string>('') // New state for the editing table name
  const [orderedLabels, setOrderedLabels] = useState<string[]>([])
  const [labelToIdMap, setLabelToIdMap] = useState<Record<string, string>>({})

  const [validation, setValidation] = useState<ValidationResult>({ isValid: false, message: '' })

  const validateAllocations = (): ValidationResult => {
    let totalAllocation = 0
    let incompleteRows = 0

    allocationTables.forEach((table) => {
      table.rows.forEach((row) => {
        if (row.allocationPercentage) {
          totalAllocation += row.allocationPercentage
        }
        if (!row.label || !row.periodId || !row.allocationPercentage || row.formulaOptions.length === 0) {
          incompleteRows++
        }
      })
    })

    const roundedTotal = Math.round(totalAllocation * 100)

    if (roundedTotal !== 100) {
      return { isValid: false, message: `Total allocation must be 100%. Current total: ${roundedTotal}%` }
    }

    if (incompleteRows > 0) {
      return { isValid: false, message: `${incompleteRows} row(s) are incomplete` }
    }

    return { isValid: true, message: 'All allocations are valid' }
  }

  useEffect(() => {
    if (period) {
      const ordered = getOrderedLabels(period)
      setOrderedLabels(ordered)
      setLabelToIdMap(createLabelToIdMap(period))
    }
  }, [period, allocationTables])

  useEffect(() => {
    const validationResult = validateAllocations()
    setValidation(validationResult)
    setIsValid(validationResult.isValid)
  }, [allocationTables])

  const addTable = () => {
    const sectionCount = allocationTables.length
    const newTable: VariableAllocationTable = {
      label: `Section #${sectionCount + 1}`,
      rows: [],
    }
    setAllocationTables([...allocationTables, newTable])
  }

  const removeTable = (tableIndex: number) => {
    const updatedTables = allocationTables.filter((_, index) => index !== tableIndex)
    setAllocationTables(updatedTables)
  }

  const addRow = (tableIndex: number) => {
    const newRow: VariableAllocationRow = {
      label: 'New Row',
      rowType: RowType.CALCULATION_ROW,
      restrictedVisibility: false,
      allocationPercentage: 0.2,
      minAllocationPercentage: 0.1,
      maxAllocationPercentage: 0.3,
      defaultPayout: 0,
      minPayout: 0,
      maxPayout: 0,
      formulaRequirements: [],
      formulaOptions: [],
      achievementMultiplier: 'a**1.00',
      periodId: '',
    }
    const updatedTables = allocationTables.map((table, index) => {
      if (index === tableIndex) {
        return { ...table, rows: [...table.rows, newRow] }
      }
      return table
    })
    setAllocationTables(updatedTables)
  }

  const removeRow = (tableIndex: number, rowIndex: number) => {
    const updatedTables = allocationTables.map((table, index) => {
      if (index === tableIndex) {
        const updatedRows = table.rows.filter((_, rIndex) => rIndex !== rowIndex)
        return { ...table, rows: updatedRows }
      }
      return table
    })
    setAllocationTables(updatedTables)
  }

  const updateTableRows = (
    tableIndex: number,
    rowIndex: number | null,
    updateCallback: (row: VariableAllocationRow) => VariableAllocationRow
  ) => {
    const updatedTables = allocationTables.map((table, tIndex) => {
      if (tIndex === tableIndex) {
        const updatedRows = table.rows.map((row, rIndex) => {
          if (rowIndex === null || rIndex === rowIndex) {
            return updateCallback(row)
          }
          return row
        })
        return { ...table, rows: updatedRows }
      }
      return table
    })
    setAllocationTables(updatedTables)
  }

  const handleEditClick = (tableIndex: number, rowIndex: number, label: string) => {
    setEditingTableIndex(tableIndex)
    setEditingRowIndex(rowIndex)
    setEditingLabel(label)
  }

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingTableIndex !== null && editingRowIndex !== null) {
      updateTableRows(editingTableIndex, editingRowIndex, (row) => ({
        ...row,
        label: e.target.value,
      }))
      setEditingLabel(e.target.value)
    }
  }

  const handleRowLabelChange = () => {
    if (editingTableIndex !== null && editingRowIndex !== null) {
      updateTableRows(editingTableIndex, editingRowIndex, (row) => ({
        ...row,
        label: editingLabel,
      }))
      setEditingTableIndex(null)
      setEditingRowIndex(null) // Reset the editing row index
      setEditingLabel('')
    }
  }

  const handleEditTableClick = (tableIndex: number, tableName: string) => {
    setEditingTableIndex(tableIndex)
    setEditingTableName(tableName)
  }

  const handleTableNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingTableName(e.target.value)
  }

  const handleTableNameSave = () => {
    if (editingTableIndex !== null) {
      // Create a new array with all tables
      const updatedTables = allocationTables.map((table, index) => {
        // If this is the table we're editing, update its label
        if (index === editingTableIndex) {
          return { ...table, label: editingTableName }
        }
        // Otherwise, return the table as is
        return table
      })
      // Update the state with the new tables array
      setAllocationTables(updatedTables)
      // Reset editing state
      setEditingTableIndex(null)
      setEditingTableName('')
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (editingRowIndex !== null && editingTableIndex !== null) {
        handleRowLabelChange() // Existing functionality for saving row label edits
      } else if (editingTableIndex !== null) {
        handleTableNameSave() // Save table name edits when editing table name
      }
    }
  }

  const handlePeriodChange = (event: SelectChangeEvent<string>, tableIndex: number, rowIndex: number) => {
    const label = event.target.value
    const newPeriodId = labelToIdMap[label] // Lookup the ID using the label
    // Update the tables with the new period ID
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      periodId: newPeriodId,
    }))
  }

  const handleRestrictedVisibilityChange = (tableIndex: number, rowIndex: number, checked: boolean) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      restrictedVisibility: checked,
    }))
  }

  const handleRowTypeChange = (tableIndex: number, rowIndex: number, newType: RowType) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      rowType: newType,
    }))
  }

  const handleFormulaChange = (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[]
  ) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      formulaRequirements: newFormulaRequirements,
      formulaOptions: newFormulaOptions,
    }))
  }

  const handleRowUpdate = (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      formulaRequirements: newFormulaRequirements,
      formulaOptions: newFormulaOptions,
      allocationPercentage: allocationPercentage,
      minAllocationPercentage: minAllocationPercentage,
      maxAllocationPercentage: maxAllocationPercentage,
      defaultPayout: defaultPayoutPercent,
      minPayout: minPayoutPercent,
      maxPayout: maxPayoutPercent,
      achievementMultiplier: newAccelerator,
    }))
  }

  const handleUpdateAllocations = (
    tableIndex: number,
    rowIndex: number,
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      allocationPercentage: allocationPercentage,
      minAllocationPercentage: minAllocationPercentage,
      maxAllocationPercentage: maxAllocationPercentage,
      defaultPayout: defaultPayoutPercent,
      minPayout: minPayoutPercent,
      maxPayout: maxPayoutPercent,
    }))
  }

  const handleEditAchievementMultiplier = (tableIndex: number, rowIndex: number, newMultiplier: string) => {
    updateTableRows(tableIndex, rowIndex, (row) => ({
      ...row,
      achievementMultiplier: newMultiplier,
    }))
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AllocationTableDisplay
            templateName={templateName}
            allocationTables={allocationTables}
            orderedLabels={orderedLabels}
            labelToIdMap={labelToIdMap}
            editingTableIndex={editingTableIndex}
            editingRowIndex={editingRowIndex}
            editingLabel={editingLabel}
            editingTableName={editingTableName}
            handleEditClick={handleEditClick}
            handleLabelChange={handleLabelChange}
            handleLabelSave={handleRowLabelChange}
            handleEditTableClick={handleEditTableClick}
            handleTableNameChange={handleTableNameChange}
            handleTableNameSave={handleTableNameSave}
            handleKeyPress={handleKeyPress}
            handlePeriodChange={handlePeriodChange}
            handleRestrictedVisibilityChange={handleRestrictedVisibilityChange}
            handleRowTypeChange={handleRowTypeChange}
            handleEditAchievementMultiplier={handleEditAchievementMultiplier}
            handleFormulaChange={handleFormulaChange}
            handleUpdateAllocations={handleUpdateAllocations}
            handleRowUpdate={handleRowUpdate}
            addRow={addRow}
            removeRow={removeRow}
            addTable={addTable}
            removeTable={removeTable}
            targets={targets}
            organizationLabels={organizationLabels}
            periodLabels={periodLabels}
            rootPeriod={period?.label || ''}
            exampleBonusAmount={exampleBonusAmount}
            setEditingIndices={(tableIndex: number | null, rowIndex: number | null) => {
              setEditingTableIndex(tableIndex)
              setEditingRowIndex(rowIndex)
            }}
            exampleSalary={exampleSalary}
            variableCompensation={variableCompensation}
            addTargetIfNotExists={addTargetIfNotExists}
            theme={theme}
            currency={currency}
            currencyDecimals={currencyDecimals}
          />
        </Grid>
      </Grid>
      {!validation.isValid && (
        <Typography color="error" sx={{ mt: 2 }}>
          {validation.message}
        </Typography>
      )}
    </>
  )
}

export default TemplateComponents

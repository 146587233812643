import { ID, InputFormat, Label, PeriodIDs } from './common'

export enum RowType {
  DISPLAY_ROW = 'DISPLAY_ROW',
  CALCULATION_ROW = 'CALCULATION_ROW',
}

export interface ComponentIDs {
  componentIds?: string[] | null
}

export interface VariableAllocationTable extends ID, Label, ComponentIDs {
  rows: VariableAllocationRow[]
}

export interface VariableAllocationRow extends ID, PeriodIDs, Label, ComponentIDs {
  rowType: RowType
  disabled?: boolean | null
  restrictedVisibility: boolean
  allocationPercentage: number
  minAllocationPercentage?: number | null
  maxAllocationPercentage?: number | null
  defaultPayout: number
  minPayout: number
  maxPayout: number
  formulaRequirements: string[]
  formulaOptions: string[]
  achievementMultiplier: string
  formatOverride?: InputFormat | null
  componentSlugs?: string[] | null
  targetSlugs?: string[] | null
  additionalInputs?: { [key: string]: RangedValue }
  outputVariable?: string | null
  updatedTargetValues?: { [key: string]: number } | null
  updatedMinimumAchievement?: number | null
  ledgerAccountId?: string | null
}

export interface RangedValue {
  value: number
  min: number
  max: number
}

export interface VariableCompAllocationTableExtended {
  label: string
  rows: VariableCompAllocationRowExtended[]
}

export interface VariableCompAllocationRowExtended {
  includedInPlan: boolean
}

import { TextField } from '@mui/material'
import CurrencyField from 'components/CurrencyField'
import DecimalField from 'components/DecimalField'
import PercentageField from 'components/PercentageField'
import TrueFalseSelector from 'components/TrueFalseSelector'
import WholeNumberField from 'components/WholeNumberField'
import React from 'react'
import { ComponentType } from 'types/components'

interface ComponentFieldProps {
  componentType: ComponentType
  label: string
  value: string | number | boolean | null
  onChange: (value: any) => void
  name: string
  currency?: string
  currencyDecimals?: number
  decimalPlaces?: number
  disabled?: boolean
  placeholder?: string
  style?: React.CSSProperties
}

const ComponentField: React.FC<ComponentFieldProps> = ({
  componentType,
  label,
  value,
  onChange,
  name,
  currency = 'USD',
  currencyDecimals = 0,
  decimalPlaces = 2,
  disabled = false,
  placeholder,
  style, // Destructure the style prop
}) => {
  switch (componentType) {
    case ComponentType.COMPONENT_TYPE_CURRENCY:
      return (
        <CurrencyField
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          currency={currency}
          currencyDecimals={currencyDecimals}
          disabled={disabled}
          placeholder={placeholder}
          style={style} // Pass the style prop
        />
      )
    case ComponentType.COMPONENT_TYPE_INTEGER:
      return (
        <WholeNumberField label={label} value={value?.toString() ?? ''} onChange={onChange} name={name} style={style} />
      )
    case ComponentType.COMPONENT_TYPE_FLOAT:
      return (
        <DecimalField
          label={label}
          value={value?.toString() ?? ''}
          onChange={onChange}
          name={name}
          decimalPlaces={decimalPlaces}
          disabled={disabled}
          placeholder={placeholder}
          style={style} // Pass the style prop
        />
      )
    case ComponentType.COMPONENT_TYPE_PERCENTAGE:
      return (
        <PercentageField label={label} value={value?.toString() ?? ''} onChange={onChange} name={name} style={style} />
      )
    case ComponentType.COMPONENT_TYPE_BOOL:
      return <TrueFalseSelector label={label} value={value as boolean} onChange={onChange} name={name} style={style} />
    default:
      return (
        <TextField
          label={label}
          variant="standard"
          fullWidth
          type="text"
          value={value?.toString() ?? ''}
          onChange={(e) => onChange(e.target.value)}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          style={style} // Pass the style prop
        />
      )
  }
}

export default ComponentField

import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'
import { currencySymbols } from 'utils/currency'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
    />
  )
})

interface CurrencyFieldProps {
  label: string
  value: string
  onChange: (value: string) => void
  name: string
  currency: string
  currencyDecimals: number
  disabled?: boolean
  placeholder?: string
  style?: React.CSSProperties // Add the optional style property
}

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  label,
  value,
  onChange,
  name,
  currency,
  currencyDecimals,
  disabled = false,
  placeholder,
  style, // Destructure the style prop
}) => {
  const symbol = currencySymbols[currency] || currency

  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value?.toString() ?? ''}
      onChange={(e) => onChange(e.target.value)}
      name={name}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          decimalScale: currencyDecimals,
          fixedDecimalScale: currencyDecimals !== 0,
        },
        startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>,
      }}
      disabled={disabled}
      placeholder={placeholder}
      style={style} // Pass the style prop
    />
  )
}

export default CurrencyField

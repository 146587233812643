import { QuestionCircleOutlined } from '@ant-design/icons'
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Popover, Theme, Typography } from '@mui/material'
import { SearchProvider } from 'contexts/SearchContext'
import { FC, useEffect, useState } from 'react'
import { ExpandedTarget } from 'types/components'
import AdvancedRowEditor from './AdvancedRowEditor'
import SimpleRowEditor from './SimpleRowEditor'

type RowManagerProps = {
  isOpen: boolean
  tableIndex: number
  rowIndex: number
  rowLabel: string
  targets: ExpandedTarget[]
  organizationLabels: Record<string, string>
  periodId: string
  periodLabel: string
  exampleSalary: number
  variableCompensation: number
  currentRequirements: string[]
  currentOptions: string[]
  allocationPercentage: number
  minAllocationPercentage: number
  maxAllocationPercentage: number
  defaultPayoutPercent: number
  minPayoutPercent: number
  maxPayoutPercent: number
  handleClose: () => void
  handleFormulaChange: (tableIndex: number, rowIndex: number, requirements: string[], options: string[]) => void
  handleUpdateAllocations: (
    tableIndex: number,
    rowIndex: number,
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  accelerator: string
  handleUpdateAccelerator: (tableIndex: number, rowIndex: number, accelerator: string) => void
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
}

const RowManager: FC<RowManagerProps> = ({
  isOpen,
  tableIndex,
  rowIndex,
  rowLabel,
  handleClose,
  handleFormulaChange,
  targets,
  organizationLabels,
  periodId,
  periodLabel,
  exampleSalary,
  variableCompensation,
  currentRequirements: initialRequirements,
  currentOptions: initialOptions,
  allocationPercentage,
  minAllocationPercentage,
  maxAllocationPercentage,
  defaultPayoutPercent,
  minPayoutPercent,
  maxPayoutPercent,
  handleUpdateAllocations,
  accelerator,
  handleUpdateAccelerator,
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
}) => {
  const [isSimple, setIsSimple] = useState<boolean>(true)
  const [currentRequirements, setCurrentRequirements] = useState<string[]>(initialRequirements)
  const [currentOptions, setCurrentOptions] = useState<string[]>(initialOptions)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  // Update local state when props change
  useEffect(() => {
    setCurrentRequirements(initialRequirements)
    setCurrentOptions(initialOptions)
  }, [initialRequirements, initialOptions])

  const localHandleFormulaChange = (requirements: string[], options: string[]) => {
    setCurrentRequirements(requirements)
    setCurrentOptions(options)
    handleFormulaChange(tableIndex, rowIndex, requirements, options)
  }

  const toggleEditor = () => {
    if (canUseSimpleEditor) {
      setIsSimple((prev) => !prev)
    }
  }

  const formulasContain = (formula: string) => {
    return (
      currentRequirements.some((req) => req.includes(formula)) || currentOptions.some((opt) => opt.includes(formula))
    )
  }

  const filteredTargets = targets !== undefined ? targets.filter((target) => target.target.periodId === periodId) : []

  const currentTargetsUsed = filteredTargets.filter((target) => {
    const targetSlugUsed = formulasContain(target.target.slug)
    const componentSlugUsed = formulasContain(target.component.slug)
    return targetSlugUsed || componentSlugUsed
  })

  useEffect(() => {
    // Determine if simple editor can be used
    const canUseSimpleEditor = currentTargetsUsed.length <= 1 && currentOptions.length <= 1

    // Update editor state when the condition changes
    if (!canUseSimpleEditor) {
      setIsSimple(false)
    }
  }, [currentOptions, currentTargetsUsed])

  const handleAllocationChange = (
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => {
    handleUpdateAllocations(
      tableIndex,
      rowIndex,
      allocationPercentage,
      minAllocationPercentage,
      maxAllocationPercentage,
      defaultPayoutPercent,
      minPayoutPercent,
      maxPayoutPercent
    )
  }

  useEffect(() => {
    console.log('Requirements Updated:', currentRequirements)
    console.log('Options Updated:', currentOptions)
  }, [currentRequirements, currentOptions])

  const canUseSimpleEditor = currentTargetsUsed.length <= 1 && currentOptions.length <= 1

  const buttonLabel = isSimple ? 'Show Advanced Editor' : 'Show Simple Editor'

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleClearAndSwitch = () => {
    setCurrentRequirements([])
    setCurrentOptions([])
    handleFormulaChange(tableIndex, rowIndex, [], [])
    setIsSimple(true)
    handlePopoverClose()
  }

  const open = Boolean(anchorEl)

  return (
    <SearchProvider>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={false}
        fullWidth
        PaperProps={{
          style: {
            width: '100vw',
            maxWidth: '700px',
            height: '100%',
            margin: 0,
          },
        }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="h4" sx={{ mb: { xs: 2, sm: 0 } }}>
              {rowLabel !== '' ? `Customize ${rowLabel} Variable Compensation` : 'Customize Row'}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={toggleEditor} disabled={!canUseSimpleEditor} sx={{ mr: 1 }}>
                {buttonLabel}
              </Button>
              {!canUseSimpleEditor && (
                <IconButton onClick={handlePopoverOpen}>
                  <QuestionCircleOutlined />
                </IconButton>
              )}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography sx={{ mb: 2 }}>Clear selected targets and formulas to use the Simple Editor?</Typography>
                  <Button onClick={handleClearAndSwitch}>Clear and Switch</Button>
                </Box>
              </Popover>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isSimple && canUseSimpleEditor ? (
            <SimpleRowEditor
              organizationLabels={organizationLabels}
              periodLabel={periodLabel}
              periodId={periodId}
              exampleSalary={exampleSalary}
              variableCompensation={variableCompensation}
              handleCloseModal={handleClose}
              handleFormulaChange={localHandleFormulaChange}
              handleUpdateAllocations={handleAllocationChange}
              tableIndex={tableIndex}
              rowIndex={rowIndex}
              acceleratorInitial={accelerator}
              handleUpdateAccelerator={handleUpdateAccelerator}
              handleRowUpdate={handleRowUpdate}
              addTargetIfNotExists={addTargetIfNotExists}
              theme={theme}
              currency={currency}
              currencyDecimals={currencyDecimals}
            />
          ) : (
            <AdvancedRowEditor
              tableIndex={tableIndex}
              rowIndex={rowIndex}
              organizationLabels={organizationLabels}
              periodLabel={periodLabel}
              periodId={periodId}
              exampleSalary={exampleSalary}
              variableCompensation={variableCompensation}
              currentRequirements={currentRequirements}
              currentOptions={currentOptions}
              currentTargetsUsed={currentTargetsUsed}
              allocationPercentage={allocationPercentage}
              minAllocationPercentage={minAllocationPercentage}
              maxAllocationPercentage={maxAllocationPercentage}
              defaultPayoutPercent={defaultPayoutPercent}
              minPayoutPercent={minPayoutPercent}
              maxPayoutPercent={maxPayoutPercent}
              handleFormulaChange={localHandleFormulaChange}
              handleUpdateAllocations={handleAllocationChange}
              handleCloseModal={handleClose}
              acceleratorInitial={accelerator}
              handleUpdateAccelerator={handleUpdateAccelerator}
              handleRowUpdate={handleRowUpdate}
              addTargetIfNotExists={addTargetIfNotExists}
              theme={theme}
              currency={currency}
              currencyDecimals={currencyDecimals}
            />
          )}
        </DialogContent>
      </Dialog>
    </SearchProvider>
  )
}

export default RowManager

import { Autocomplete, CircularProgress, Grid, InputLabel, Stack, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useCreatePlanContext } from 'contexts/CreatePlanContextProvider'
import useAuth from 'hooks/useAuth'
import { useFetchUser, useSearchUsers } from 'hooks/useUsers'
import { CompensationPlan } from 'types/comp-plan'
import { UserRole } from 'types/role'
import { User } from 'types/user'
import { UserProfile } from 'types/user-profile'

const EmployeeDetailForm = () => {
  const { token } = useAuth()
  const {
    compensationTemplate,
    plan,
    setPlan,
    mode,
    setEmployeeName,
    originalEffectiveDate,
    showOriginalEffectiveDate,
    withUserId,
  } = useCreatePlanContext()

  const [profile, setProfile] = useState<UserProfile>({
    roles: [] as UserRole[],
    user: { firstName: '', lastName: '', position: '' } as User,
  } as UserProfile)

  const { users, isLoading: userSearchIsLoading } = useSearchUsers(
    token!,
    {
      organizationIds: compensationTemplate?.template.organizationIds,
      perPage: 1000,
    },
    compensationTemplate != null && compensationTemplate.template.organizationIds != null
  )

  const userId = withUserId ? withUserId : plan && plan.userId ? plan.userId : null

  const { userProfile, isLoading: userIsLoading } = useFetchUser(token!, userId, null)

  const [selectedOrganization, setSelectedOrganization] = useState<string>('')
  const [effectiveDate, setEffectiveDate] = React.useState<dayjs.Dayjs | null>(null)

  useEffect(() => {
    // Force the user to select and organization and effective date to move forward
    let tempPlan = { ...plan }
    tempPlan.organizationId = ''
    tempPlan.effectiveDate = ''
    setPlan(tempPlan)
  }, [])

  useEffect(() => {
    if (userProfile?.user) {
      setEmployeeName(`${userProfile.user.firstName} ${userProfile.user.lastName}`)
      if (!plan.position) {
        setPlan((prevPlan: CompensationPlan) => ({ ...prevPlan, position: '' }))
      }
      if (!plan.userId) {
        setPlan((prevPlan: CompensationPlan) => ({
          ...prevPlan,
          userId: userProfile.user.id,
          position: userProfile.user.position,
        }))
      }
      console.log('userProfile', userProfile)
      setProfile(userProfile)
    }
  }, [userProfile, plan.id, plan.position, setEmployeeName, setPlan, withUserId])

  useEffect(() => {
    if (showOriginalEffectiveDate && plan.effectiveDate) {
      setEffectiveDate(dayjs(plan.effectiveDate))
    }
  }, [plan.effectiveDate, showOriginalEffectiveDate])

  const setOrganizationHandler = (value: string) => {
    setSelectedOrganization(value)
    let tempPlan = { ...plan }
    tempPlan.organizationId = value
    setPlan(tempPlan)
  }

  const onChangeEffectiveDate = (newValue: dayjs.Dayjs | null) => {
    if (newValue) {
      setEffectiveDate(newValue)
      let tempPlan = { ...plan }
      tempPlan.effectiveDate = newValue.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
      setPlan(tempPlan)
    }
  }

  const onChangeSelectedEmployee = (event: any, newValue: any) => {
    if (compensationTemplate) {
      const matchId = newValue ? newValue.id : ''
      const selectedUser = users.find((user) => user.id === matchId)
      let tempPlan = { ...plan }
      tempPlan.userId = selectedUser?.id || ''
      tempPlan.position = selectedUser?.position || ''
      setEmployeeName(selectedUser?.firstName + ' ' + selectedUser?.lastName || '')
      setPlan(tempPlan)
    }
  }

  const onChangePosition = (event: React.ChangeEvent<HTMLInputElement>) => {
    let tempPlan = { ...plan }
    tempPlan.position = event.target.value
    setPlan(tempPlan)
  }

  if (userSearchIsLoading || userIsLoading) {
    return (
      <Grid container spacing={12}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress color="primary" />
          Loading
        </Grid>
      </Grid>
    )
  }

  return (
    <React.Fragment>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <InputLabel id="template-name-label">Template Name</InputLabel>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={compensationTemplate?.template.label}
                  variant="standard"
                  disabled={!!plan.id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1}>
                <InputLabel id="template-name-label">Intended Role</InputLabel>
                <TextField
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={compensationTemplate?.template.position}
                  variant="standard"
                  disabled={!!plan.id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={1} sx={{ flexGrow: 1 }}>
                <InputLabel id="employee-position-label">Position</InputLabel>
                <TextField
                  id="employee-position"
                  value={plan.position || ''}
                  onChange={onChangePosition}
                  InputProps={{
                    readOnly: mode === 'REVIEW',
                  }}
                  variant="standard"
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} lg={4}>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <InputLabel id="employee-select-label">Employee</InputLabel>
              {userProfile?.user && (plan.id || withUserId) ? (
                <TextField
                  key="employee-select"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                  value={profile.user ? `${profile.user.firstName} ${profile.user.lastName}` : ''}
                  variant="standard"
                  disabled={true}
                />
              ) : (
                <Autocomplete
                  id="employee-select"
                  options={users}
                  autoHighlight
                  getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
                  onChange={onChangeSelectedEmployee}
                  value={profile?.user ? profile.user : ({ firstName: '', lastName: '', position: '' } as User)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      variant="standard"
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SingleOrganizationSelector
              key="employee-organization-select"
              userProfile={profile}
              selectedOrganizationId={selectedOrganization}
              handleChange={(value) => setOrganizationHandler(value)}
              label="Select Team"
              showLabelAbove={true}
              style={{ marginTop: 12, paddingBottom: 0 }}
              disabled={!plan.userId}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <InputLabel id="employee-position-label">
                Effective Date
                {!showOriginalEffectiveDate &&
                  originalEffectiveDate &&
                  ` (Original: ${dayjs(originalEffectiveDate).format('MMM D, YYYY')})`}
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  value={effectiveDate}
                  onChange={onChangeEffectiveDate}
                  disabled={mode === 'REVIEW'}
                  slotProps={{
                    textField: {
                      variant: 'standard',
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default EmployeeDetailForm

import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import CompensationChart from 'components/CompensationChart'
import React from 'react'
import { RowType, VariableAllocationTable } from 'types/comp-allocations'

type TemplateReviewProps = {
  allocationTables: VariableAllocationTable[]
  periodIdToLabelMap: Record<string, string>
  label: string
  position: string
  periodName: string
  basePlus: boolean
  exampleSalary: number
  variableCapMin: number
  variableCapDefault: number
  variableCapMax: number
  variablePercentMin: number
  variablePercentDefault: number
  variablePercentMax: number
  organizationNames: string[] | null
  currency: string
  currencyDecimals: number
}

const config = {
  loader: { load: ['input/tex', 'output/svg'] },
  tex: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
  },
}

const TemplateReview: React.FC<TemplateReviewProps> = ({
  allocationTables,
  periodIdToLabelMap,
  label,
  position,
  periodName,
  basePlus,
  exampleSalary,
  variableCapMin,
  variableCapDefault,
  variableCapMax,
  variablePercentMin,
  variablePercentDefault,
  variablePercentMax,
  organizationNames,
  currency,
  currencyDecimals,
}) => {
  const baseSalary = basePlus ? exampleSalary : exampleSalary * (1 - variablePercentDefault)
  const bonusAmount = basePlus ? exampleSalary * variablePercentDefault : exampleSalary - baseSalary

  return (
    <MathJaxContext config={config}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={8}>
              <div style={{ marginBottom: '20px' }}>
                <Typography variant="h3">{label}</Typography>
                <Typography variant="body1">Position: {position}</Typography>
                <Typography variant="body1">
                  {organizationNames != null ? `Organizations: ${organizationNames.join(', ')}` : `Organization: `}
                </Typography>
                <Typography variant="body1">Fiscal Year: {periodName}</Typography>
                <Typography variant="body1">Base Plus: {basePlus ? 'Yes' : 'No'}</Typography>
                <Typography variant="body1">
                  Example Salary: $
                  {exampleSalary
                    ? exampleSalary.toLocaleString(undefined, {
                        minimumFractionDigits: currencyDecimals,
                        maximumFractionDigits: currencyDecimals,
                      })
                    : '$0'}
                </Typography>
                <Typography variant="body1">
                  Variable Cap: {variableCapMin * 100}% (Min), {variableCapDefault * 100}% (Default),{' '}
                  {variableCapMax * 100}% % (Max)
                </Typography>
                <Typography variant="body1">
                  Variable Percent: {variablePercentMin * 100}% (Min), {variablePercentDefault * 100}% (Default),{' '}
                  {variablePercentMax * 100}% (Max)
                </Typography>
              </div>
            </Grid>
            <Grid item xs={4}>
              <CompensationChart
                salary={baseSalary}
                variable={bonusAmount}
                currency={currency}
                currencyDecimals={currencyDecimals}
              />
            </Grid>
          </Grid>
        </Grid>
        {allocationTables.map((table, tableIndex) => (
          <Grid item xs={12} key={tableIndex}>
            <Typography variant="h4" style={{ margin: '10px' }}>
              {table.label}
            </Typography>
            <Table size="small" style={{ marginBottom: '20px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Display Only</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Restricted Visibility</TableCell>
                  <TableCell>Default Allocation %</TableCell>
                  <TableCell>Accelerator</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {table.rows.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell style={{ width: '250px' }}>{row.label}</TableCell>
                    <TableCell>{row.rowType === RowType.DISPLAY_ROW ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{periodIdToLabelMap[row.periodId || ''] || 'N/A'}</TableCell>
                    <TableCell>{row.restrictedVisibility ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      {row.allocationPercentage ? `${Math.round(Number(row.allocationPercentage) * 100)}%` : '0%'}
                    </TableCell>
                    <TableCell>
                      <MathJax>{`$$value^{${row.achievementMultiplier.replace(/[^\d.-]/g, '')}}$$`}</MathJax>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ))}
      </Grid>
    </MathJaxContext>
  )
}

export default TemplateReview

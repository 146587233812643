export enum InputFormat {
  INPUT_FORMAT_INTEGER = 'INPUT_FORMAT_INTEGER',
  INPUT_FORMAT_CURRENCY = 'INPUT_FORMAT_CURRENCY',
  INPUT_FORMAT_PERCENT = 'INPUT_FORMAT_PERCENT',
  INPUT_FORMAT_BOOL = 'INPUT_FORMAT_BOOL',
  INPUT_FORMAT_FLOAT = 'INPUT_FORMAT_FLOAT',
}

type TimestampString = string // Intended format: "DD-MM-YYYY HH:mm:ss"

export interface ID {
  id?: string | null
}

export interface OrganizationIDs {
  organizationId: string
  rootOrganizationId: string
}

export interface UserID {
  userId?: string | null
}

export interface PeriodIDs {
  periodId?: string | null
  rootPeriodId?: string | null
}

export interface Label {
  label: string
}

export interface Value {
  value: string
}

export interface Revision {
  revision: number
}

export interface MetaField extends Value, Pick<Timestamps, 'createdAt' | 'updatedAt'> {
  dataType: string
}

export interface Timestamps {
  createdAt: TimestampString
  updatedAt: TimestampString
  deletedAt?: TimestampString | null
}

export interface PaginationInfo {
  currentPage: number
  perPage: number
  totalItems: number
  totalPages: number
}

export interface ErrorResponse {
  error: string
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import AnimateButton from 'components/@extended/AnimateButton'
import React from 'react'
// import { useNavigate } from 'react-router-dom'

// import useAuth from 'hooks/useAuth'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { createPlan } from 'api/plan'
import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router'
import { CompensationPlanCreateDTO } from 'types/comp-plan'
import { Component } from 'types/components'
import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'
import CompensationPlanDetailForm from './CompensationPlanDetailForm'
import ComponentSelectionForm from './ComponentSelectionForm'
import EmployeeDetailForm from './EmployeeDetailForm'
import ReviewPlan from './ReviewPlan'

const CreatePlanWizard = () => {
  const { token } = useAuth()
  const navigate = useNavigate()
  const { plan, validPlanConfig, setMode, targetDTOs, compensationTemplate } = useCreatePlanContext()

  // Wizard data
  const [activeStep, setActiveStep] = React.useState(0)
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false)
  const [isMutating, setIsMutating] = React.useState(false)
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: '',
    severity: 'info',
  })

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false })
  }

  const stepLabels = ['Employee Information', 'Compensation Plan', 'Components', 'Review Plan']

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        setMode('EDIT')
        return <EmployeeDetailForm />
      case 1:
        setMode('EDIT')
        return (
          <React.Fragment>
            <CompensationPlanDetailForm />{' '}
          </React.Fragment>
        )
      case 2:
        setMode('EDIT')
        return (
          <React.Fragment>
            <ComponentSelectionForm />
          </React.Fragment>
        )
      case 3:
        setMode('REVIEW')
        return <ReviewPlan />
      default:
        throw new Error('Unknown Comp Plan Creation Step')
    }
  }

  const handleNext = () => {
    setActiveStep(activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        if (plan.userId === '' || plan.effectiveDate === '') {
          return false
        }
        return true
      case 1:
        if (
          plan.baseSalary > 0 &&
          plan.variablePercent > 0 &&
          plan.variableCap >= 0 &&
          plan.variablePercent <= plan.variableCap
        ) {
          return true
        }
        return false
      case 2:
        let allocationTotal = 0
        plan.variableAllocations.forEach((component) => {
          component.rows.forEach((row) => {
            const disabled = row.disabled != undefined && row.disabled != null && row.disabled

            if (row.rowType !== 'DISPLAY_ROW' && !disabled) {
              allocationTotal += row.allocationPercentage * 100
            }
          })
        })

        if (allocationTotal === 100) {
          return true
        }
        return false
      case 3:
        return validPlanConfig
      default:
        return false
    }
  }

  const savePlan = async () => {
    setIsMutating(true)

    const componentMap = compensationTemplate
      ? compensationTemplate.components.reduce(
          (acc, component) => {
            if (component.id != null) {
              acc[component.id] = component
            }
            return acc
          },
          {} as Record<string, Component>
        )
      : {}

    const planTargets = targetDTOs
      .filter(
        (target) => componentMap[target.componentId] != null && !componentMap[target.componentId].isOrganizationLevel
      )
      .filter((target) => {
        return plan.variableAllocations.some((table) => {
          return table.rows.some((row) => {
            return (
              row.formulaRequirements.some((formula) => {
                if (formula.includes(target.slug)) {
                  return true
                }
                return false
              }) ||
              row.formulaOptions.some((formula) => {
                if (formula.includes(target.slug)) {
                  return true
                }
                return false
              })
            )
          })
        })
      })
      .map((target) => {
        target.userId = plan.userId

        if (target.value != null) {
          target.value = target.value.toString()
        }
        return target
      })

    const planComponentIds = compensationTemplate?.components
      .filter((component) => {
        return plan.variableAllocations.some((table) => {
          return table.rows.some((row) => {
            return (
              row.formulaRequirements.some((formula) => {
                if (formula.includes(component.slug)) {
                  return true
                }
                return false
              }) ||
              row.formulaOptions.some((formula) => {
                if (formula.includes(component.slug)) {
                  return true
                }
                return false
              })
            )
          })
        })
      })
      .map((component) => component.id)
      .filter((id): id is string => id != null)

    let tempPlan: CompensationPlanCreateDTO = {
      plan: plan,
      components: planComponentIds != null ? [...new Set(planComponentIds)] : [],
      targets: planTargets,
    }

    tempPlan.plan.templateId = compensationTemplate?.template.id ?? null
    tempPlan.plan.templateRevision = compensationTemplate?.template.revision ?? null

    try {
      await createPlan(token!, tempPlan)
      setSnackbar({ open: true, message: 'Plan created successfully!', severity: 'success' })
      React.startTransition(() => {
        navigate('/admin/templates')
      })
    } catch (e) {
      setSnackbar({ open: true, message: 'Failed to create plan.', severity: 'error' })
      setIsMutating(false)
    }
  }

  const handleCancelConfirmation = () => {
    setOpenCancelDialog(false)
    React.startTransition(() => {
      navigate('/admin/templates')
    })
  }

  return (
    <React.Fragment>
      <div>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {stepLabels.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {getStepContent(activeStep)}
        <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
          {activeStep !== 0 && (
            <Button disabled={isMutating} onClick={handleBack} sx={{ my: 3, ml: 1 }}>
              Back
            </Button>
          )}
          <Stack direction="row" spacing={2}>
            <AnimateButton>
              <Button
                disabled={isMutating}
                variant="contained"
                color="warning"
                onClick={() => {
                  setOpenCancelDialog(true)
                }}
                sx={{ my: 3, ml: 1 }}
              >
                Cancel
              </Button>
            </AnimateButton>
            <AnimateButton>
              <Button
                variant="contained"
                disabled={!validateStep() || isMutating}
                onClick={activeStep === 3 ? savePlan : handleNext}
                sx={{ my: 3, ml: 1 }}
              >
                {activeStep === 3 ? 'Save Plan' : activeStep === 2 ? 'Review' : 'Next'}
              </Button>
            </AnimateButton>
          </Stack>
        </Stack>
      </div>

      <Dialog open={openCancelDialog} onClose={() => setOpenCancelDialog(false)}>
        <DialogTitle>{'Confirm cancellation'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to cancel building this plan?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCancelDialog(false)}>No</Button>
          <Button onClick={handleCancelConfirmation} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  )
}

export default CreatePlanWizard

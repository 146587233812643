import { CloseCircleOutlined } from '@ant-design/icons'
import { Box, CircularProgress, Grid, IconButton, Tooltip } from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import SearchInput from 'components/SearchInput'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useSearchComponents } from 'hooks/useComponents'
import useLocalStorage from 'hooks/useLocalStorage'
import { useProfile } from 'hooks/useProfile'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { ComponentSearchResponse } from 'types/components'
import { getOrganizationLabelMap } from 'types/user-profile'
import ComponentsTable from '../ComponentsTable'

const ManageComponents: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useLocalStorage<string[]>(
    'components-selected-organizations',
    []
  )
  const [page, setPage] = useLocalStorage<number>('components-page', 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>('components-rows-per-page', 25)

  const [organizationLabelMap, setOrganizationLabelMap] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [components, setComponents] = useState<ComponentSearchResponse>({
    components: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })

  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)

  const { componentSearchResult, isLoading: isLoadingComponents } = useSearchComponents(
    token!,
    {
      organizationIds: selectedOrganizations,
      searchTerm,
      page: page,
      perPage: rowsPerPage,
    },
    true
  )

  useEffect(() => {
    setIsLoading(isLoadingComponents || isLoadingProfile)
  }, [isLoadingComponents, isLoadingProfile])

  useEffect(() => {
    if (componentSearchResult) {
      console.log('Updating components state with new search results:', componentSearchResult.components.length)
      setComponents(componentSearchResult)
    }
  }, [componentSearchResult])

  useEffect(() => {
    if (profile) {
      const newOrganizationLabelMap = getOrganizationLabelMap(profile)
      setOrganizationLabelMap(newOrganizationLabelMap)
      console.log(organizationLabelMap)
    }
  }, [profile])

  useEffect(() => {}, [selectedOrganizations, searchTerm])

  const handleSearch = useCallback((value: string) => {
    setSearchTerm(value)
  }, [])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <SimpleTitle title="Components" />
          <Grid container spacing={4} mb={3}>
            <Grid item xs={3} container justifyContent="center">
              <SearchInput key="search-input" onSearch={handleSearch} initialValue={searchTerm} />
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MultiOrganizationSelector
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
              />
            </Grid>
            <Grid item xs={1}>
              <Tooltip title="Reset Filters">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedOrganizations([])
                    setSearchTerm('')
                  }}
                  sx={{ mt: 2 }}
                >
                  <CloseCircleOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <ComponentsTable
            key="components-table"
            components={components.components}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalItems={components.paginationInfo.totalItems}
          />
        </Box>
      )}
    </div>
  )
}

export default ManageComponents

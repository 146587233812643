import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { InputFormat } from 'types/common'
import { Component } from 'types/components'
import { KeyedObject } from 'types/root'

interface ColumnProps {
  id: string | null
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: Component) => string | boolean | JSX.Element
}

interface ComponentsTableProps {
  components: Component[]
  page: number
  rowsPerPage: number
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  totalItems: number
}

const ComponentsTable: React.FC<ComponentsTableProps> = ({
  components,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  totalItems,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    { id: 'organizationName', label: 'Team', minWidth: 200 },
    {
      id: 'isOrganizationLevel',
      label: 'Organization Level',
      minWidth: 85,
      format: (value: any, row: Component) => {
        return value ? 'Yes' : 'No'
      },
    },
    {
      id: 'format',
      label: 'Format',
      minWidth: 85,
      format: (value: any, row: Component) => {
        switch (value) {
          case InputFormat.INPUT_FORMAT_INTEGER:
            return 'Integer'
          case InputFormat.INPUT_FORMAT_PERCENT:
            return 'Percent'
          case InputFormat.INPUT_FORMAT_CURRENCY:
            return 'Currency'
          case InputFormat.INPUT_FORMAT_BOOL:
            return 'Boolean'
          case InputFormat.INPUT_FORMAT_FLOAT:
            return 'Decimal'
          default:
            return 'None'
        }
      },
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 100,
      format: (value: any, row: Component) => (
        <Box display="flex">
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() =>
                React.startTransition(() => {
                  navigate(`/admin/components/${row.id}/edit`)
                })
              }
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  return (
    <Paper sx={{ mb: 3 }}>
      <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead
            sx={{
              '& th': {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `2px solid ${theme.palette.divider} !important`,
              },
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  id={'cell-col-' + (column.id || '')}
                  sx={{
                    minWidth: column.minWidth,
                    position: 'sticky !important',
                  }}
                  key={column.id}
                  align={column.align}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody key="table-body">
            {components.map((row: KeyedObject) => (
              <TableRow id={'tble-row-' + row.id} sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.id}>
                {columns.map((column) => {
                  const value = column.id ? row[column.id] : row
                  return (
                    <TableCell id={'cell-' + column.id} key={column.id} align={column.align}>
                      {column.format ? column.format(value, row as Component) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
            <TableRow
              id={'create-new-component'}
              sx={{ py: 3 }}
              role="checkbox"
              tabIndex={-1}
              key={'create-new-component'}
            >
              <TableCell colSpan={columns.length} align="left">
                <IconButton
                  color="primary"
                  onClick={() =>
                    React.startTransition(() => {
                      navigate('/admin/components/create')
                    })
                  }
                >
                  <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <PlusOutlined style={{ marginRight: 8, marginLeft: 35 }} />
                    Create
                  </Box>
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider />
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 200]}
        component="div"
        count={totalItems}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

export default ComponentsTable
